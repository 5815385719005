.section4-container {
    background: rgba(93, 231, 231, 0.2);
    padding-top: 32px;
    padding-bottom: 32px;
}

.sec4-textcontainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
}

.sec4-showmore {
    display: block;
}

.sec4-showless {
    display: none;
}

.sec4-showmore-button {
    margin-top: 24px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-decoration-line: underline;
    color: #008b8b;
}

.sec4-showmore-button:hover {
    color: #333333;
    cursor: pointer;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .ref-card-container {
        margin-left: 96px;
        margin-right: 96px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .ref-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 30px;
        margin-right: 30px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .ref-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 30px;
        margin-right: 30px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .ref-card-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }
}
/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1354px) {
    .ref-card-container {
        /* margin-left: 136px; */
        justify-content: center;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .ref-card-container {
        justify-content: center;
    }
}

@media (min-width: 1600px) {
    .ref-card-container {
        margin-left: 10%;
        margin-right: 10%;
    }
}

@media (min-width: 2100px) {
    .ref-card-container {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media (min-width: 2400px) {
    .ref-card-container {
        margin-left: 20%;
        margin-right: 20%;
    }
}
