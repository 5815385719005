.contact-section-container {
    margin-top: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    background: rgba(93, 231, 231, 0.2);
}
.contact-section-container a {
    text-decoration: none;
}

.contact-info-container {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    display: flex;
    margin: 8px;
    padding: 24px;
}

.contact-info-container-map {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    margin: 8px;
    padding: 24px;
}

.contact-info-container1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
}

.contact-info-image-container {
    width: 46px;
    margin-left: 24px;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-info-image {
}

.contact-info-image-telephone {
    margin-left: 14px;
    margin-right: 30px;
}

.contact-info-text {
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .contact-info-c {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .contact-info-container {
        width: 42%;
        height: 112px;
        margin: auto;
        margin-bottom: 32px;
        align-items: center;
    }
    .contact-info-container-map {
        margin-top: 0;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .contact-section-container {
        padding-left: 96px;
        padding-right: 96px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1355px) {
    .contact-section-container {
        padding-left: 136px;
    }
    .contact-info-container {
        height: 156px;
        align-items: center;
        margin: 0;
        padding: 0;
        margin: 12px;
    }
    .contact-info-c {
        width: 100%;
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .contact-info-container-map {
        height: 320px;
        margin: 0;
        margin-top: 32px;
        flex-direction: row;
        padding: 24px;
        padding-right: 24px;
        justify-content: normal;
        align-items: center;
        margin: 12px;
    }
    .contact-info-container1 {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36%;
        margin: 0;
    }
    .gmap {
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
