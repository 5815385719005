.comment-container {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    padding: 32px;
    margin: 8px;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.commenter-container {
    display: flex;
    align-items: center;
}

.commenter-image {
    width: 120px;
    object-fit: cover;
}

.commenter-info-container {
    margin-left: 16px;
}

.comment {
    margin-top: 18px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .comment-container {
        width: 60%;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .comment-container {
        width: 50%;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .comment-container {
        width: 40%;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .comment-container {
        width: 40%;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
