.gallery-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px;
    margin-bottom: 32px;
}
.gallery-image-container:hover {
    cursor: pointer;
    opacity: 0.9;
}
.gci {
    width: 100%;
}

.gallery-image {
    width: 100%;

    background: #d8d8d8;
    border-radius: 3px 3px 0 0;
}

.gallery-image-info-container {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 0 0 3px 3px;
}

.gallery-image-infos {
    padding-left: 32px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .gallery-image {
        height: 340px;
        object-fit: cover;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .gallery-image-container {
        margin: auto;
        width: 48%;
        margin-bottom: 32px;
    }
    .gallery-image {
        height: 240px;
        object-fit: cover;
    }
    .gallery-image-container {
        margin: auto;
        width: 30%;
        margin-bottom: 32px;
    }

    .gallery-image-info-container {
        height: 96px;
    }
    .gallery-image-infos {
        padding-left: 32px;
        padding-right: 32px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .gallery-image-container {
        margin: auto;
        width: 30%;
        margin-bottom: 32px;
    }

    .gallery-image-info-container {
        height: 96px;
    }
    .gallery-image-infos {
        padding-left: 32px;
        padding-right: 32px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .gallery-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 370px;
        height: 288px;
        margin: 0;

        margin-bottom: 32px;
    }

    .gallery-image {
        width: 370px;
        height: 240px;
        background: #d8d8d8;
        border-radius: 3px;
        position: absolute;
    }

    .gallery-image-info-container {
        width: 338px;
        height: auto;
        padding-top: 24px;
        padding-bottom: 24px;
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
        border-radius: 3px;
        position: relative;
        margin: 0 16px;
        top: 192px;
    }

    .gallery-image-infos {
        padding-left: 32px;
    }
    .gallery-image-container {
        margin-left: 12px;
        margin-right: 12px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .gallery-image-container {
        margin-left: 6px;
        margin-right: 6px;
    }
}

@media (min-width: 1600px) {
    .gallery-image-container {
        margin-left: 24px;
        margin-right: 24px;
    }
}
