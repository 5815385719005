.sec3-container {
    padding-top: 32px;
}

.sec3-textcontainer {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 16px;
}

.sec3-header2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #008b8b;
    margin-bottom: 12px;
    border: 1px solid;
    height: 0;
    width: 145px;
    align-self: center;
}

.sec3-header1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    color: #333333;
    margin-bottom: 12px;
}

.sec3-infottext {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #333333;
}

.sec3-servicescontainer {
}

.sec3-service1 {
    background: #1b1b26;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.sec3-service1-imagecontainer {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 170px;
    border-radius: 3px;
    margin-bottom: 15px;
}
.imagecontainer-number1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.1;
    margin-top: 8px;
    margin-left: 12px;
}

.sec-3-services-image {
}

.sec3-service-list,
.sec3-service-list1 {
    text-align: center;
}

.sec3-service1-list-header {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-top: 8px;
    margin-bottom: 8px;
}

.sec3-list1 {
    padding-right: 24px;
    padding-left: 24px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.7);
}

.sec3-service2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.sec3-service2-imagecontainer {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 170px;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    margin-bottom: 15px;
}

.imagecontainer-number2 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.1;

    margin-top: 8px;
    margin-left: 12px;
}

.sec-3-services-image-panel {
    width: 89px;
    height: 89px;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}

.sec-3-services-image-mech {
    width: 55px;
    height: 96px;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}
.sec-3-services-image-robot {
    width: 89px;
    height: 86px;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}
.sec-3-services-image-electric {
    width: 52px;
    height: 85px;
    align-self: center;
    margin-top: auto;
    margin-bottom: auto;
}
.sec3-service-list-header {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    margin-top: 8px;
    margin-bottom: 8px;
}

.sec3-service-list {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: rgba(51, 51, 51, 0.5);
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .sec3-service1 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .sec3-service1-imagecontainer {
        display: flex;
        flex-direction: column;
        width: 170px;
        height: 170px;
        border-radius: 3px;
        margin-bottom: 15px;
    }
    .imagecontainer-number1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: #ffffff;
        mix-blend-mode: normal;
        opacity: 0.1;
        margin-top: 8px;
        margin-left: 12px;
    }
    .sec3-service-list1 {
        text-align: left;
        width: 45%;
        margin-left: 112px;
    }
    .sec3-service1-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.7);
    }
    .bottom-services {
        display: flex;
        justify-content: space-between;
        margin-left: 135px;
        margin-right: 135px;
        margin-top: 32px;
        margin-bottom: 56px;
    }
    .sec3-service2 {
    }
    .sec3-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        color: #333333;
        margin-bottom: 24px;
    }
    .sec3-header2 {
        margin-bottom: 24px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .bottom-services {
        display: flex;
        justify-content: space-between;
        margin-left: 195px;
        margin-right: 195px;
        margin-top: 60px;
        margin-bottom: 56px;
    }
    .sec3-service1 {
        padding-top: 68px;
        padding-bottom: 68px;
        padding-left: 235px;
        padding-right: 235px;
        justify-content: left;
    }
    .sec3-service1-text {
    }
    .sec3-service-list1 {
        width: 100%;
        margin-left: 71px;
    }
    .sec3-header1 {
        margin-top: 60px;
        margin-bottom: 24px;
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        color: #333333;
        margin-bottom: 24px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
