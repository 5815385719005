.footer-container {
}

.top-footer-container {
    background-color: #1b1b26;
    padding-top: 32px;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-logo {
    width: 112px;
    margin-bottom: 16px;
}

.top-footer-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    margin-bottom: 16px;
}

.footer-social-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-social {
    color: #ffffff;
    margin-right: 12px;
    margin-left: 12px;
    margin-top: 8px;
    margin-bottom: 24px;
}

.footer-social:hover {
    cursor: pointer;
    color: #333333;
}

.footer-right-container {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
}

.footer-list-container {
    margin-left: 16px;
    margin-right: 16px;
}

.footer-list-header {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
}

.footer-list {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.5);
}

.bottom-footer-container {
    background-color: #008b8b;
    padding: 16px;
    min-height: 40px;
    align-items: center;
}

.bottom-footer-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: #1b1b26;
    margin-top: 8px;
}

.contact-us-container {
    margin-top: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.contact-us-container p {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    color: white;
    text-align: center;
    margin-bottom: 12px;
}

.contact-us-container input,
.contact-us-container textarea {
    height: 32px;
    border: 1px solid rgba(255, 255, 255, 0.295063);
    background: rgba(93, 231, 231, 0.1);
    border-radius: 3px;
    margin: 4px 12px;
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    color: white;
    padding-left: 8px;
}

.contact-us-container textarea {
    min-height: 64px;
    resize: vertical;
}

.contact-us-container textarea:focus,
.contact-us-container input:focus {
    outline: none;
}

.contact-us-container button {
    width: 112px;
    height: 36px;
    font-size: 16px;
    border: solid 1px #ffffff4b;
    border-radius: 3px;
    align-self: center;
    margin-top: 4px;
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0);
}

.contact-us-container button:hover {
    background-color: #008b8b;
    cursor: pointer;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .top-footer-container {
        display: flex;
        flex-direction: row;
    }
    .footer-left-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 96px;
        margin-right: 136px;
    }
    .top-footer-text {
        text-align: left;
        padding: 0;
    }
    .footer-logo {
        width: 169px;
        margin-bottom: 16px;
    }
    .footer-social {
        margin-right: 24px;
        margin-left: 0;
    }
    .footer-right-container {
        margin-right: 96px;
    }
    .bottom-footer-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .bottom-footer-text {
        margin-right: 4px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #1b1b26;
    }

    .contact-us-container {
        margin-top: 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        width: 60%;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .footer-left-container {
        margin-left: 136px;
        margin-right: auto;
        margin-top: 47px;
        margin-bottom: 76px;
        width: 35%;
    }
    .footer-right-container {
    }
    .footer-list-container {
        margin-right: 0;
        margin-left: 140px;
    }
    .contact-us-container {
        margin-top: 32px;
        margin-bottom: 32px;
        margin-right: 76px;
        display: flex;
        flex-direction: column;
        width: 30%;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
