.carousel-arrow-back {
    position: absolute;
    transform: translate(0, -50%);
    left: 0px;
    z-index: 1;
    cursor: pointer;
    color: black;
}

.carousel-arrow-next {
    position: absolute;
    transform: translate(0, -50%);
    bottom: 0px;
    right: 45%;
    z-index: 1;
    cursor: pointer;
    color: black;
}
.dots {
    cursor: pointer;
    margin-left: 4px;
    margin-right: 4px;
}

.dots-container {
    margin-top: 12px;
    top: 100%;
    align-self: flex-end;
}

.carousel-container {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.carousel {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.carousel-section-center {
    height: 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-section-center article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    transition: all 1s linear;
    display: flex;
    align-items: center;
    justify-content: center;
}
.itemContainer {
}
article.activeItem {
    opacity: 1;
    transform: translateX(0);
}
article.lastItem {
    opacity: 1;
    transform: translateX(-90%);
}
article.lastLastItem {
    opacity: 0;
    transform: translateX(-180%);
}
article.nextItem {
    opacity: 1;
    transform: translateX(90%);
}
article.nextNextItem {
    opacity: 0;
    transform: translateX(180%);
}

@media (max-width: 375px) {
    article.activeItem {
        opacity: 1;
        transform: translateX(0);
    }
    article.lastItem {
        opacity: 1;
        transform: translateX(-100%);
    }
    article.lastLastItem {
        opacity: 0;
        transform: translateX(-200%);
    }
    article.nextItem {
        opacity: 1;
        transform: translateX(100%);
    }
    article.nextNextItem {
        opacity: 0;
        transform: translateX(200%);
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    article.lastItem {
        opacity: 1;
        transform: translateX(-80%);
    }
    article.lastLastItem {
        opacity: 0;
        transform: translateX(-160%);
    }
    article.nextItem {
        opacity: 1;
        transform: translateX(80%);
    }
    article.nextNextItem {
        opacity: 0;
        transform: translateX(160%);
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    article.lastItem {
        opacity: 1;
        transform: translateX(-60%);
    }
    article.lastLastItem {
        opacity: 0;
        transform: translateX(-120%);
    }
    article.nextItem {
        opacity: 1;
        transform: translateX(60%);
    }
    article.nextNextItem {
        opacity: 0;
        transform: translateX(120%);
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .carousel {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .carousel-section-center {
        width: 100%;
        margin-left: 96px;
        margin-right: 96px;
    }
    .dots-container {
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .carousel-section-center {
        width: 100%;
        margin-left: 135px;
        margin-right: 135px;
    }
    article.lastItem {
        opacity: 1;
        transform: translateX(-55%);
    }
    article.lastLastItem {
        opacity: 0;
        transform: translateX(-110%);
    }
    article.nextItem {
        opacity: 1;
        transform: translateX(55%);
    }
    article.nextNextItem {
        opacity: 0;
        transform: translateX(110%);
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
