.slider-container {
    width: 100%;
    height: 320px;
    margin: 0 auto;
    margin-top: 84px;
}

.slider {
    height: 100%;
    position: relative;
}

.arrow-back {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 16px;
    z-index: 1;
    cursor: pointer;
    color: white;
}

.arrow-next {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 16px;
    z-index: 1;
    cursor: pointer;
    color: white;
}

.section-center {
    height: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
    display: flex;
    overflow: hidden;
    /* background: linear-gradient(
        90.22deg,
        #1b1b26 -2.05%,
        rgba(27, 27, 38, 0) 97.57%
    ); */
    background-color: #000a14;
}

article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.6s linear;
}
article.activeSlide {
    opacity: 1;
    transform: translateX(0);
}
article.lastSlide {
    transform: translateX(-100%);
}
article.nextSlide {
    transform: translateX(100%);
}
.text-container-slider {
    width: 70%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 15%;
    text-align: left;
}

.slider-header1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 38px;
    text-transform: uppercase;
    color: #ffffff;
    margin-top: 16px;
    margin-bottom: 12px;
}

.slider-header2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #008b8b;
}

.slider-info-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    text-align: left;
    overflow: hidden;
}
@media (min-width: 375px) {
    .text-container-slider {
        width: 55%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 15%;
        text-align: left;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .text-container-slider {
        width: 45%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 15%;
        text-align: left;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .slider-container {
        width: 100%;
        height: calc(100vh - 84px);
        margin: 0 auto;
        margin-top: 84px;
    }
    .text-container-slider {
        width: 65%;
        top: 50%;
        transform: translate(0, -50%);
        left: 15%;
        text-align: left;
    }

    .slider-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 64px;
        line-height: 70px;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 52px;
        margin-bottom: 24px;
    }

    .slider-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        margin-bottom: -20px;
        color: #008b8b;
    }

    .slider-info-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 22px;
        color: #ffffff;
        text-align: left;
    }
    /* .slider-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        text-transform: uppercase;
        color: #ffffff;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .slider-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #008b8b;
    }

    .slider-info-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
        text-align: left;
        overflow: hidden;
    } */
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .arrow-back {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 24px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }

    .arrow-next {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 24px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }
    .slider-container {
        width: 100%;
        height: calc(100vh - 84px);
        margin: 0 auto;
        margin-top: 84px;
    }
    .text-container-slider {
        width: 65%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 96px;
        text-align: left;
    }

    .slider-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 60px;
        line-height: 72px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 12px;
    }

    .slider-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
        color: #008b8b;
    }

    .slider-info-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        color: #ffffff;
        text-align: left;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .slider-container {
        width: 100%;
        height: calc(100vh - 84px);
        margin: 0 auto;
        margin-top: 84px;
    }
    .slider {
        height: 100%;
        position: relative;
    }

    .arrow-back {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 32px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }

    .arrow-next {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 32px;
        z-index: 1;
        cursor: pointer;
        color: white;
    }

    .section-center {
        height: 100%;
        max-width: 100%;
        text-align: center;
        position: relative;
        display: flex;
        overflow: hidden;
        /* background: linear-gradient(
            90.22deg,
            #1b1b26 -2.05%,
            rgba(27, 27, 38, 0) 97.57%
        ); */
        background-color: #000a14;
    }

    article {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all 0.6s linear;
    }
    article.activeSlide {
        opacity: 1;
        transform: translateX(0);
    }
    article.lastSlide {
        transform: translateX(-100%);
    }
    article.nextSlide {
        transform: translateX(100%);
    }

    .text-container-slider {
        width: 55%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 136px;
        text-align: left;
    }
    .slider-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 76px;
        line-height: 90px;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 14px;
    }

    .slider-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 24px;
        color: #008b8b;
    }

    .slider-info-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 32px;
        color: #ffffff;
        text-align: left;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
@media (min-width: 1600px) {
    .text-container-slider {
        width: 45%;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        left: 136px;
        text-align: left;
    }
}
