.headerContainerOutside {
    width: 100%;
    opacity: 0.95;
}
.headerContainer {
    width: 100%;
    height: 84px;
    display: flex;
    align-items: center;
    background: #1b1b26;
    color: #ffffff;
    font-family: "DM Sans", sans-serif;
}
.sticky {
    position: fixed;
    top: 0;
    z-index: 3;
}

.logo {
    width: 112px;
    margin-right: 32px;
    margin-left: 16px;
}

.logo:hover {
    cursor: pointer;
}

.phone-number {
    margin-right: auto;
    font-size: 20px;
    color: white;
    text-decoration: none; /* no underline */
}
.phone-number:hover {
    color: #008b8b;
    cursor: pointer;
}
.contact-container {
    width: 112px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: 24px;
    font-size: 16px;
}
.contact-container div {
    border: solid 1px #ffffff4b;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.contact-container div:hover {
    background-color: #008b8b;
    cursor: pointer;
}
.contact-container div li {
    width: auto;
    height: auto;
}

.links {
    display: block;
    border-top: solid 1px #ffffff4b;
    background: #1b1b26;
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    top: 84px;
}
.links-inactive {
    display: none;
}

.nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link {
    text-decoration: none;
    color: #ffffff;
    width: 100%;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}
.link:hover {
    background-color: #008b8b;
    cursor: pointer;
}

.nav-item-mobile {
}

.icon-container {
    margin-right: 16px;
}

.hamburger-menu {
    color: #ffffff;
}

.hamburger-menu-active {
    color: #008b8b;
}

.hamburger-menu:hover {
    cursor: pointer;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .headerContainer {
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .logo {
        margin-left: 96px;
        width: 169px;
    }
    .nav-items {
        display: flex;
        flex-direction: row;
        margin-right: auto;
        justify-content: center;
        align-items: center;
    }
    .link {
        margin-left: 12px;
        margin-right: 12px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
    }
    .link:hover {
        background-color: transparent;
        color: #008b8b;
    }
    .contact-container {
        margin-right: 96px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
    }
    .phone-number {
        font-size: 12px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .logo {
        margin-left: 136px;
    }
    .contact-container {
        margin-right: 136px;
    }
    .phone-number {
        font-size: 18px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
