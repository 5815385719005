.image-container {
    background: url(../images/solarpanels.png);
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 256px;
}

.comment-section-container {
    background: rgba(93, 231, 231, 0.2);
    padding-top: 32px;
    padding-bottom: 32px;
}

.comments {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .image-container {
        background: url(../images/solarpanels.png);
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 450px;
    }
    .comment-section-container {
        padding-left: 96px;
        padding-right: 96px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .comment-section-container {
        padding-left: 135px;
        padding-right: 135px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
