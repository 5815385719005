.refCard-container {
    display: flex;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.100345);
    border-radius: 3px;
    padding: 8px 12px;
    margin: 12px;
    min-height: 150px;
    justify-content: center;
    align-items: center;
}
.refCard-number {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
    mix-blend-mode: normal;
    opacity: 0.05;
}
.refCard-bottom-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: left;
    padding-top: 24px;
    padding-bottom: 24px;
}

.refCard-image {
    width: 96px;
    height: 75px;
    margin-right: 24px;
}

.refCard-header {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.refCard-bottom-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    justify-content: left;
    padding: 0;
}

@media (min-width: 320px) {
    .refCard-image {
        width: 120px;
        height: 75px;
        margin-right: 24px;
    }
    .refCard-bottom-container-right {
    }
    .refCard-header {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #333333;
    }
    .refCard-info {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(51, 51, 51, 0.5);
    }
    .refCard-bottom-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: left;
        padding: 0;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .refCard-container {
        width: 42%;
        margin: auto;
        margin-bottom: 32px;
        height: 140px;
    }
    .refCard-bottom-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: left;
        padding: 0;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .refCard-container {
        width: 370px;
        height: 176px;
        margin: 12px 12px 12px 12px;
        /* margin-bottom: 32px;
        margin-right: 16px;
        margin-left: 0; */
    }

    .refCard-number {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
        mix-blend-mode: normal;
        opacity: 0.05;
    }
    .refCard-bottom-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        justify-content: left;
        padding: 0;
    }
    .refCard-image {
        width: 120px;
        height: 75px;
        margin-right: 32px;
        margin-left: 12px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 1355px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
