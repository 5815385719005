html {
    scroll-behavior: smooth;
}
.App {
}

.text-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
}

.header1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    color: #333333;
    margin-bottom: 12px;
}

.header2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #008b8b;
    margin-bottom: 8px;
}

.header3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 6px;
}

.commenter-company {
}
.info-text {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #333333;
}

.info-text2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.5);
}

.white-header1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    width: 256px;
}

.white-header2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 8px;
}
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}

.whatsapp-icon {
    margin-top: 10px;
}

.whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .text-container {
        width: 50%;
        margin: auto;
        margin-bottom: 20px;
    }

    .header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        color: #333333;
        margin-bottom: 24px;
    }

    .header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #008b8b;
        margin-bottom: 24px;
    }
    .info-text {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #333333;
    }

    .white-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 56px;
        line-height: 64px;
        text-align: center;
        color: #ffffff;
        width: 70%;
    }

    .white-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 24px;
    }
    .whatsapp_float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #25d366;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 1px 0px 3px #999;
        z-index: 100;
    }

    .whatsapp-icon {
        margin-top: 16px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
    .info-text {
    }
    .header2 {
        margin-top: 60px;
        margin-bottom: 24px;
    }
    .text-container {
        width: 42%;
        margin: auto;
        margin-bottom: 20px;
    }
    .white-header1 {
        width: 534px;
    }
}
