.sec2-container {
    background: rgba(93, 231, 231, 0.2);
    display: flex;
    flex-direction: column;
    padding: 24px;
}

.sec2-leftcontainer {
}

.sec2-header2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #008b8b;
    margin-bottom: 8px;
}

.sec2-header1 {
    font-family: "Alfa Slab One";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
    color: #333333;
    margin-bottom: 12px;
}

.sec2-text1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 12px;
}

.sec2-text2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(51, 51, 51, 0.5);
    margin-bottom: 12px;
}

.sec2-rightcontainer {
}

.sec2-listcontainer {
    margin-bottom: 32px;
}

.sec2-listheader {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #008b8b;
    margin-bottom: 8px;
}

.sec2-list {
}

.sec2-listitem {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
}

.sec2-social-container {
}

.sec2-socialheader {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #008b8b;
    margin-bottom: 12px;
}

.sec2-social {
    color: #008b8b;
    margin-right: 12px;
}

.sec2-social:hover {
    cursor: pointer;
    color: #333333;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .sec2-container {
        padding-left: 96px;
        padding-right: 96px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .sec2-leftcontainer {
        width: 55%;
    }
    .sec2-rightcontainer {
        margin-left: 135px;
    }

    .sec2-header2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #008b8b;
        margin-bottom: 24px;
    }

    .sec2-header1 {
        font-family: "Alfa Slab One";
        font-style: normal;
        font-weight: 400;
        font-size: 72px;
        line-height: 80px;
        color: #333333;
        margin-bottom: 24px;
    }

    .sec2-text1 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #333333;
        margin-bottom: 24px;
    }

    .sec2-text2 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(51, 51, 51, 0.5);
        margin-bottom: 24px;
    }

    .sec2-listheader {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #008b8b;
        margin-bottom: 8px;
    }

    .sec2-listitem {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
    }

    .sec2-socialheader {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #008b8b;
        margin-bottom: 12px;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .sec2-container {
        padding-left: 136px;
        padding-right: 136px;
        padding-top: 116px;
        padding-bottom: 170px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .sec2-leftcontainer {
        width: 50%;
    }
    .sec2-rightcontainer {
        margin-left: 230px;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
