.gallery-section {
    padding-top: 32px;
}

.images-container {
    margin-left: 16px;
    margin-right: 16px;
}
/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
    .info-text {
        margin-left: 32px;
        margin-right: 32px;
    }
    .images-container {
        margin-left: 64px;
        margin-right: 64px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .images-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;
        margin-right: 0;
    }
    .gallery-showmore-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .images-container {
        margin-top: 48px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .gallery-showmore-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 96px;
        margin-right: 96px;
        justify-content: space-between;
    }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .images-container {
        margin-top: 48px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 1px;
        margin-right: 1px;
        margin-bottom: 48px;
        justify-content: center;
    }
}

/* // XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
}
